@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import "../node_modules/@photo-sphere-viewer/core/index.css";
html {
  --section-background-color: linear-gradient(to bottom left,
      rgb(255 255 255 / 58%),
      rgba(255, 255, 255, 0.9));

  --image-gradient: linear-gradient(to bottom left,
      rgba(17, 16, 16, 0.678),
      rgba(12, 10, 22, 0.863));

  --imp-text-color: #2b2b2c;
  /* 294fa5 */
}

.purple {
  color: var(--imp-text-color) !important;
}

button:focus {
  box-shadow: none !important;
}

h1, h2, h3, h4, h5, h6{
  font-weight: 300 !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #2b2b2c;
  background-image: url(./Assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2b2b2c;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(221, 221, 221, 0.911);
  border-radius: 12px;
}



/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  background-color: #2b2b2c69 !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
  background-color: #2b2b2c;
  height: 10vh;
}


.responsive-navbar-nav{
  color: #000;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #ffffff !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

.startPageImgs{
  height: 100vh;
}

@media (max-width: 992px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #2b2b2c !important;
    height: auto;
  }
  .quizPageQuizArea{
    margin-top: 105px !important;
  }
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #2b2b2c !important;
  }

  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}

@media (max-width: 540px) {
  .navbarLogoImg{
width: 140px !important;
  }

}

.navbar-brand {
  color: rgb(250, 250, 250) !important;
}

/* .logo {
  height: 1.4em !important;
  width: 2.5em !important;
} */

.navbar-nav .nav-link {
  color: white !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #e2e2e2;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* --------- */
/* Home section */
/* --------- */
.wave {
  animation-name: wave-animation;
  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s;
  /* Change to speed up or slow down */
  animation-iteration-count: infinite;
  /* Never stop waving :) */
  transform-origin: 70% 70%;
  /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(14deg);
  }

  /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }

  30% {
    transform: rotate(14deg);
  }

  40% {
    transform: rotate(-4deg);
  }

  50% {
    transform: rotate(10deg);
  }

  60% {
    transform: rotate(0deg);
  }

  /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

.home-header {
  padding-top: 80px !important;
}

.home-section {
  position: relative;
  z-index: -1;
  height: 100vh;
  /* background-image: var(--image-gradient), url(./Assets/home-bg.jpg); */

  background-position: top center;
  background-repeat: no-repeat;
}

.home-content {
  padding: 16rem 0 2rem !important;
  color: whitesmoke;
  text-align: left;
  position: absolute;
  top: 0;
  height: 100vh;
  left: 50%;
  transform: translate(-50%);
}

.heading {
  font-size: 2.4em !important;
}

.heading-name {
  font-size: 2.5em !important;
  padding-left: 45px !important;
}

.main-name {
  color: #cd5ff8;
}

.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: #2b2b2c !important;
  font-weight: 600 !important;
}

.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #2b2b2c !important;
}
.homeStartPageTitle{
  margin-top: 20px;
}
.homePageStartRow{
  position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    margin-left: 1px !important;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 2em !important;
    font-weight: 500 !important;

  }
  .Typewriter__cursor {
    display: none !important;
  }
  .homeStartPageTitle{
    font-size: 1.7em !important;
  }
}

@media (max-width: 545px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;

  }
  .homeStartPageTitle{
    font-size: 1.4em !important;
  }
}

.myAvtar {
  justify-content: center !important;
  padding-top: 9em !important;
}

@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

.home-about-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
}

.home-about-description {
  color: rgb(0, 0, 0) !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.home-about-body {
  padding-top: 50px;
  font-size: 1.2em !important;
  text-align: center;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: rgb(0, 0, 0) !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #68187a;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #e2e2e2;
}

.home-social-icons:hover {
  color: #87209e;
  box-shadow: 0 0 5px #87209e;
  text-shadow: 0 0 2px #87209e;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-colour {
  color: #700c86 !important;
}

/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: rgb(10, 4, 22);
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important;
}

.footer-copywright {
  text-align: center !important;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}

.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: #a588c0 !important;
}

/* --------- */
/* Projects */
/* --------- */
.project-section {
  position: relative !important;
  padding-top: 50px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
}

.project-card {
margin-bottom: 30px;
}

.project-card-view {
  /* box-shadow: 0 4px 5px 3px #2b2b2c !important; */
  color: black !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
  border: none !important;
}

.project-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  /* box-shadow: 0 4px 4px 5px rgba(129, 72, 144, 0.561) !important; */
}

.blog-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.blog-card-view {
  background-color: transparent !important;
  box-shadow: 0 3px 3px 2px rgba(145, 77, 161, 0.459) !important;
  color: white !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}

.blog-link {
  color: white !important;
  text-decoration: none !important;
}

.blog-link:hover {
  cursor: pointer !important;
}

.blog-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 3px 3px 5px rgba(155, 88, 173, 0.65) !important;
}

.card-img-top {
  /* padding: 20px !important; */
  height: 80%;
  object-fit: cover;
  border-radius: 0px !important;
  transition: filter 0.3s ease;
  /* Geçiş efekti için */
}

.card-img-top:hover {
  filter: brightness(40%);
  /* Hover olduğunda karartma efekti */
}

.blog-img {
  padding: 0px !important;
  opacity: 0.8 !important;
  border-radius: 0px !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #2b2b2c !important;
  border-color: #141414 !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #3c5da8 !important;
  border-color: #424242d7 !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.project-heading {
  color: black !important;
  padding-top: 10px !important;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 2.5em;
}

/* --------- */
/* About */
/* --------- */

.about-section {
  position: relative !important;
  /* padding-top: 95px ; */
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: rgb(0, 0, 0) !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.tech-icons {
  font-size: 4.5em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 1.7px solid #2b2b2c !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}

@media (max-width: 767px) {
  .tech-icons {
    margin: 10px !important;
  }
}

.tech-icons:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 2.2px solid rgba(0, 0, 0, 0.883) !important;
}

.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}

.quote-card-view {
  border: none !important;
  color: black !important;
  background-color: transparent !important;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}

/* --------- */
/* Resume */
/* --------- */

.resume-section {
  position: relative !important;
  padding-top: 80px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.resume {
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
}

.resume-left {
  padding-right: 80px !important;
}

.resume-right {
  padding-left: 80px !important;
}

@media (max-width: 767px) {
  .resume-left {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .resume-right {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

.resume .resume-title {
  font-size: 2em;
  font-weight: 700;
  padding-top: 30px;
  padding-bottom: 30px;
}

.resume .resume-item {
  padding: 0 0 10px 25px;
  margin-top: -2px;
  border-left: 2px solid #8a49a8;
  position: relative;
}

.resume .resume-item .resume-title {
  line-height: 18px;
  font-size: 0.9em;
  background: #5234795d;
  padding: 8px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.resume .resume-item ul {
  padding-left: 20px;
  text-align: justify;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
  list-style: none;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #8a49a8;
}

.like-item {
  padding-top: 10px !important;
  font-size: 1.1em !important;
  font-family: sans-serif !important;
}

.like-btn {
  background-color: #934cce5e !important;
  border-color: #934cce5e !important;
  padding: 0.25rem 0.98rem !important;
  border-radius: 5px !important;
  line-height: 1.4 !important;
  transition: 0.3s ease !important;
}

.like-btn:hover {
  transform: translateY(-2px) !important;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
}

.animate-like {
  animation-name: likeAnimation;
  animation-fill-mode: forwards;
  animation-duration: 0.85s;
}

@keyframes likeAnimation {
  0% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.fork-btn {
  font-size: 1.1em !important;
  padding-top: 10px !important;
}

.fork-btn-inner {
  line-height: 1.4em !important;
  background-color: #e2e2e25e !important;
  padding: 0.25rem 1.1rem !important;
  vertical-align: middle !important;
  text-align: center !important;
}

.fork-btn-inner:hover {
  transform: translateY(-2px) !important;
  background-color: #e2e2e25e !important;
  border-color: #e2e2e25e !important;
}

.fork-btn-inner::after {
  display: none !important;
}

.homepageVideoDiv {
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

.homepageVideoDiv::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

#myVideo {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  z-index: -2;
  left: 0;
}

.projectInfoDiv {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.projectInfoDivDetailButton {
  background-color: #2b2b2c !important;
}

.projectInfoDivDetailButton:hover {
  background-color: #2b2b2c !important;
  scale: 1.2;
}


.projectsPhotoDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.projectsPhotoDiv img {
  width: 400px;
  height: 250px;
  margin: 5px;
  object-fit: cover;
}

.projectsPhotoDiv img:hover {
  transform: scale(1.02);
  cursor: pointer;
}

.card-body {
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactPageContact {
  background-color: #2b2b2c;
  border-radius: 10px;
  color: black;
}

.navbarContainer {
  height: 130px;
}

.navbarLogoBrand {
  height: 100%;
  width: 20%;
  justify-content: flex-start;
  margin-right: 0;
  padding: 0;
}

.navbarTextCollapse {
  width: 70%;
  justify-content: flex-start;
}

.navbarLogoImg {
  width: 200px;
  /* height: 120px; */
}

.navbarLanguageCollapse {
  justify-content: center;
  width: 10%;
}

.contactFormArea {
  background-color: #2b2b2c;
  padding: 40px;
}

.contactFormTextDiv {
  height: 100%;
  text-align: left;
}

.contactFormTextLine {
  width: 40%;
  height: 3px;
  background-color: white;
  margin-top: 20px;
  margin-bottom: 20px;
}

.contactFormTextAdressInfoDiv {
  margin-top: 20px;
}

.contactFormContactSubmitButton {
  background-color: white;
}


.contactFormTextAdressInfoDiv p {
  margin: 0;
}

.wrapper {
  width: 100%;
  -webkit-box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 1);
}

.contactForm .form-control {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0;
}

#contactForm .form-control {
  font-size: 16px;
}


.form-control {
  height: 36px;
  background: #fff;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  border-radius: 2px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(0, 0, 0, 0.3) !important;
}

.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(0, 0, 0, 0.3) !important;
}

.form-control:-ms-input-placeholder {
  /* IE 0+ */
  color: rgba(0, 0, 0, 0.3) !important;
}

.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(0, 0, 0, 0.3) !important;
}

.form-control:focus,
.form-control:active {
  border-color: #01d28e !important;
}


.contactForm .label {
  color: #000;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}

.contactFormContactArea {
  color: black;
  text-align: left;
}

.info-wrap {
  color: rgba(255, 255, 255, 0.8);
}

.info-wrap h3 {
  color: #fff;
}

.info-wrap .dbox {
  width: 100%;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 25px;
}

.info-wrap .dbox:last-child {
  margin-bottom: 0;
}

.info-wrap .dbox p {
  text-align: left;
  margin-left: 20px;
  margin-bottom: 0;
}

.info-wrap .dbox p span {
  font-weight: 500;
  color: #fff;
}

.info-wrap .dbox p a {
  color: #fff;
}

.info-wrap .dbox .icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.2);
}

.info-wrap .dbox .icon span {
  font-size: 20px;
  color: #fff;
}

.info-wrap .dbox .text {
  width: calc(100% - 50px);
}

.contactFormSendButton {
  margin-top: 30px;
}

.contactFormInfoArea {
  background-color: #2b2b2c !important;
  font-size: 15px;
  text-align: justify;
}

.contact-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contactFormInfoIcon {
  margin-top: 20px;
  font-size: 40px;
}

.instagramIcon:hover {
  cursor: pointer;
  border-radius: 50%;
  background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}

.pinterestIcon:hover {
  cursor: pointer;
  color: #E60023;
}


.aboutReferenceBannerRow {
  width: 101%;
  height: 30vh;
  background-color: #2b2b2c;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  margin: 0;
}

.aboutReferenceCarousel div img {
  width: 20%;
}

.slick-slider {
  height: 200px;
}

.slick-list {
  height: 100% !important;
}

.slick-track {
  height: 100% !important;
}

.aboutReferenceCarouselImageDiv {
  height: 100%;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.slick-slide div {
  height: 100%;
}

.slick-dots {
  display: none !important;
}


/* ABOUT PAGE */

.aboutPageAboutTextContainer {
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: auto !important;
  margin-left: auto !important;
  max-width: 1172px !important;
  width: 95% !important;
}

.aboutPageAboutTextTitle {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  letter-spacing: 0.1em;
  font-size: 2em;
  margin-bottom: 5px;
  text-align: center;
  padding-bottom: 2px;
  display: inline-block;
  margin: 0 auto;
  line-height: 1.1em;
}

/* .aboutPageAboutTextArea {
  padding-top: 30px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px !important;
} */

.aboutPageAboutTextImage {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.aboutSectorsBannerRow {
  /* background-color: #2b2b2c; */
  overflow: hidden;
  min-height: 100px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 80px;
  position: relative;
  box-sizing: inherit;
}

.bsoldcard{
  word-wrap: break-word;
    background-clip: border-box;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    min-width: 0;
    position: relative;
}

.aboutSectorsRowBox {
  text-align: center;
  overflow: hidden;
  opacity: 1;
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: auto !important;
  margin-left: auto !important;
  max-width: 1172px !important;
  width: 95% !important;

}

.aboutSectorsCard {
  position: relative;
  overflow: hidden;
  padding: 0px;
  border: 3px solid transparent !important;
  position: relative !important;
  overflow: hidden;
  background-color: #fff0 !important;
  border-radius: 0 !important;
  flex-direction: row !important;
  cursor: pointer;
  height: 320px;
  width: 100%;
}

.aboutSectorsCard::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.3), transparent);
  mix-blend-mode: multiply;
  z-index: 1;
  transition: opacity 0.3s ease;
}

.aboutSectorsCard:hover::before {
  opacity: 0;
}

.aboutSectorsImageDiv {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.aboutSectorsImage {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.aboutSectorsImage:hover {
  transform: scale(1.05);
}


.aboutSectorsImage {
  width: 800px;
  height: 800px;
  border: 0;
  overflow-clip-margin: content-box;
  overflow: clip;
  height: auto;
  max-width: 100%;
  aspect-ratio: auto 800 / 800;
}


.aboutPageStartPage {
  height: 100%;
  position: relative;
}

.aboutPageStartPageImg {
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: brightness(70%);
}

.aboutPageStartPageTitle {
  position: absolute;
  left: 50%;
  top: 65%;
  color: white;
  transform: translate(-50%, -50%);
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  letter-spacing: 0.1em;
  font-size: 2.5em;
  line-height: 1.3em;
  z-index: 1;
}

.aboutSectorImageText {
  bottom: 30px;
  left: 50%;
  transform: translate(-50%);
  color: white;
  position: absolute;
  z-index: 2;
  margin: 0;
  width: 100%;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.0em;

}

.aboutStartPageParallax {
  position: relative;
  min-height: 300px;
}

.aboutStartPageParallax::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("./Assets/weusstartpageimg.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(70%);
}

.projectStartPageParallax {
  position: relative;
  min-height: 300px;
}

.projectStartPageParallax::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("./Assets/projectstartpageimg.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(70%);
}


.projectPageStartPageTitle {
  position: absolute;
  left: 50%;
  top: 50%;
  color: white;
  transform: translate(-50%, -50%);
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  letter-spacing: 0.1em;
  font-size: 2.5em;
  line-height: 1.3em;
  z-index: 1;
}

.contactStartPageParallax {
  position: relative;
  min-height: 300px;
}

.contactStartPageParallax::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("./Assets/contactusstartpageimg.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(70%);
}

.contactPageStartPageTitle {
  position: absolute;
  left: 50%;
  top: 60%;
  color: white;
  transform: translate(-50%, -50%);
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  letter-spacing: 0.1em;
  font-size: 2.5em;
  line-height: 1.3em;
  z-index: 1;
}

.stylequizStartPageParallax {
  position: relative;
  min-height: 300px;
}

.stylequizStartPageParallax::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("./Assets/stylequizstartpageimg.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(70%);
}


.stylequizPageStartPageTitle {
  position: absolute;
  left: 50%;
  top: 50%;
  color: white;
  transform: translate(-50%, -50%);
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  letter-spacing: 0.1em;
  font-size: 2.5em;
  line-height: 1.3em;
  z-index: 1;
}

.parallax {
  /* The image used */
  background-image: url("./Assets/parallaxphotohome.jpg");

  /* Set a specific height */
  min-height: 300px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;
}

.homepageparallax2 {
  /* The image used */
  background-image: url("./Assets/homepageparallax2img.jpg");

  /* Set a specific height */
  min-height: 300px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;
}

.aboutpageparallax1 {
  /* The image used */
  background-image: url("./Assets/parallaxphotoabout1.jpg");

  /* Set a specific height */
  min-height: 300px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;
}

@media only screen and (max-width: 550px) {

  .aboutStartPageParallax::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url("./Assets/weusstartpageimg.jpg");
    background-attachment: scroll;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: brightness(70%);
  }

  .projectStartPageParallax::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url("./Assets/projectstartpageimg.jpg");
    background-attachment: scroll;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: brightness(70%);
  }


  .contactStartPageParallax::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url("./Assets/contactusstartpageimg.jpg");
    background-attachment: scroll;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: brightness(70%);
  }

  .stylequizStartPageParallax::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url("./Assets/stylequizstartpageimg.jpg");
    background-attachment: scroll;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: brightness(70%);
  }
  .parallax {
    background-attachment: scroll;
  }

  .homepageparallax2 {
    background-attachment: scroll;
  }

  .aboutpageparallax1 {
    background-attachment: scroll;
  }

}

.homePageParallaxText1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 22px;
  font-weight: 600 !important;
}
@media only screen and (max-width: 465px) {
  .homePageParallaxText1 {
font-size: 17px;
  }
}

.homePageParallaxText2 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 22px;
  font-weight: 600 !important;
}

.aboutPageParallaxText1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 22px;
  font-weight: 600 !important;
}

.projectPageParallaxText1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 22px;
  font-weight: 600 !important;
}

.contactPageParallaxText1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 22px;
  font-weight: 600 !important;
}

.stylequizPageParallaxText1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 22px;
  font-weight: 600 !important;
}

.siteNavbar {
  padding: 10px 0px 10px;
  position: fixed;
  top: 0px;
  margin: 0 auto;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 1000;
  -webkit-transition: background-color 0.3s ease-in-out, opacity 1s ease-in-out !important;
  transition: background-color 0.3s ease-in-out, opacity 1s ease-in-out !important;
}

.siteContainer {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1172px;
  width: 95%;
}

.siteBrand {
  position: relative;
  float: left;
  overflow: hidden;
}

.siteTitle {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 0;
  font-size: 1.9em;
  letter-spacing: -0.07em;
  max-width: 272px;
}

a {
  background-color: transparent;
  text-decoration: none;
  color: black;
}

.siteBrandLogo {
  max-width: 100%;
  height: 54px;
  width: auto;
  top: 0;
  left: 0;
  z-index: 1000;
  line-height: 1;
  display: block;
  position: relative;
  transition: opacity 0.3s ease;
}

.siteHomePageNav {
  text-align: center;
}

.siteHomePageNavContainer {
  margin-right: 100px;
}

.siteHomePageNavUl {
  display: inline-block;
  width: auto;
  margin: 0 auto;
  padding: 0;
  position: relative;
  top: 14px;
  list-style: disc;
  text-align: center;
}

.siteHomePageNavLi {
  display: inline;
  padding: 0 13px;
}

.siteHomePageNavTranslateDiv {
  position: absolute;
  top: 9px;
  right: 10px;
  padding: 11px 27px !important;
  background-color: transparent;
  color: #000 !important;
  border: 1px solid #000;
  font-weight: normal;
  font-size: 13px;
  line-height: 1;
  letter-spacing: 0.1em;

}

.footer-navigate{
  cursor: pointer;
  text-decoration: underline;
}
.footer-navigate:hover{
color: #a8a8a8 !important;
}

.footerArea {
  background-color: #2b2b2c !important;
  color: white;
}


.contactPageStartPage {
  height: 100%;
  position: relative;
}

.contactPageStartPageImg {
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: brightness(70%);
}


.projectCardImgDiv {
  position: relative;
  height: 100%;
  object-fit: cover;
}

.projectCardImgDiv::after {
  content: "";
  position: absolute;
  left: 20px;
  top: 20px;
  right: 20px;
  bottom: 0;
  opacity: .9;
  border: 1px solid #2b2b2c;
  border-width: 1px 1px 0;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
}

.projectCardImg {
  width: 100%;
  height: 250px;
  object-fit: inherit;
}

@media only screen and (max-width: 1200px) {
  .projectCardImg {
    width: 100%;
    height: 100%;
  }
}

.projectCardTextDiv {
  position: relative !important;
  padding: 25px 50px 0 !important;
  display: block !important;
}

.projectCardTextDiv::before {
  content: "";
  position: absolute;
  height: 50px;
  width: 1px;
  background-color: #2b2b2c;
  opacity: .9;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
  top: -10px;
  left: 20px;
}

.projectCardTextDiv::after {
  content: "";
  position: absolute;
  height: 50px;
  width: 1px;
  background-color: #2b2b2c;
  opacity: .9;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
  top: -10px;
  right: 20px;
}

.projectCardTitleTextDiv {
  position: relative;
  overflow: hidden;
  font-size: 15px;
  margin-left: -30px;
  margin-right: -30px;
  padding: 0 30px;
}

.projectCardTitle {
  position: relative;
  display: inline-block;
  color: #000000;
  font-size: 18px;
  font-family: "Open Sans",sans-serif;
}

.projectCardTitle::before {
  content: "";
  position: absolute;
  top: 15px;
  height: 1px;
  width: 650px;
  background-color: #2b2b2c;
  opacity: .9;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
  left: 100%;
    margin-left: 15px;
}

.projectCardTitle::after {
  content: "";
  position: absolute;
  top: 15px;
  height: 1px;
  width: 650px;
  background-color: #2b2b2c;
  opacity: .9;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
  right: 100%;
    margin-right: 15px
}


.projectDetailTextArea{
  padding: 50px 0px;
  background-color: #2b2b2c;
}
.aboutCardPageWhoWeAreTextArea{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.aboutCardPageWhoWeAreImg{
  height: 100%;
  object-fit: cover;
}

.aboutPageWhoWeAreText{
  font-family: "Open Sans",sans-serif;
  font-size: .7em;
    line-height: 1.7em;
    font-weight: 300;
    text-align: center;
    font-size: 17px;
    padding: 10px 20% 40px 20%;
}
.abputWhoWeAreTitle{
  font-family: "Roboto", sans-serif;
    font-weight: 300;
    letter-spacing: 3px;
    font-size: 2.4em;
    margin-bottom: 40px !important;
    text-align: center;
    display: inline-block;
    margin: 0 auto;
    line-height: 1.1em;
}
.aboutPageAboutRow{
  -webkit-box-shadow: 0px 10px 13px -3px  rgb(211 211 211 / 75%);
    -moz-box-shadow: 0px 10px 13px -3px  rgb(211 211 211 / 75%);
    box-shadow: 0px 10px 13px -3px rgb(211 211 211 / 75%);
}


@media only screen and (max-width: 992px) {
  .aboutPageWhoWeAreText{
    padding: 10px 10% 40px 10%;
  }
}
@media only screen and (max-width: 767px) {
  .aboutPageAboutRow{
    height: auto;
  }
}
@media only screen and (max-width: 600px) {
  .aboutPageAboutTextArea {
padding: 20px !important;
  }
}
@media only screen and (max-width: 420px) {
  .aboutPageWhoWeAreText {
 font-size: 14px;
  }
}


.quizPageStartPage {
  height: 100%;
  position: relative;
}

.quizPageStartPageImg {
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: brightness(70%);
}


.quizPageStartPageTitle {
  position: absolute;
  left: 50%;
  top: 50%;
  color: white;
  transform: translate(-50%, -50%);
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  letter-spacing: 0.1em;
  font-size: 2.5em;
  line-height: 1.3em;
}
.quizPageStartPageDescription{
  font-family: "Open Sans",sans-serif;
  letter-spacing: 0 !important;
  font-size: 20px;
}
.quizPageStartPageButton{
  font-family: "Open Sans",sans-serif;
  letter-spacing: 0 !important;
  font-size: 20px;
  color: white;
  background-color: #2b2b2c;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 5px;
}
.quizPageStartPageButton:hover{
  color: black;
  font-size: 22px;
  background-color: #ffffff;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 5px;
}

.quizPageQuizArea{
  /* height: 90vh; */
  margin-top: 95px;
}

.containerImage{
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: auto !important;
  margin-left: auto !important;
  max-width: 1172px !important;
  width: 95% !important;
}

@media only screen and (min-width: 1400px) {
  .styleDesignQuizRow{
padding-left: 100px !important;
padding-right: 100px !important;
  }
}
/* @media only screen and (max-width: 992px) {
.quizPageQuizArea{
  margin-bottom: 200px;
}
}
@media only screen and (max-width: 768px) {
  .quizPageQuizArea{
    margin-bottom: 1850px;
  }
  }

 */

 .styleDesignQuizResultStartDiv{
  width: 100%;
  height: 300px;
  position: relative;
 }
 .styleDesignQuizResultStartTitle{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  color: white;
  letter-spacing: 0.1em;
 }

 @media only screen and (max-width: 650px) {
  .aboutPageStartPageTitle{
  font-size: 28px;
  }
  .projectPageStartPageTitle{
    font-size: 28px;
  }
  .contactPageStartPageTitle{
    font-size: 28px;
  }
  .stylequizPageStartPageTitle{
    font-size: 28px;
  }
  .stylequizPageStartPageTitle{
    font-size: 28px;
  }

}
@media only screen and (max-width: 370px) {
  .aboutPageStartPageTitle{
  font-size: 22px;
  }
  .projectPageStartPageTitle{
    font-size: 22px;
  }
  .contactPageStartPageTitle{
    font-size: 22px;
  }
  .stylequizPageStartPageTitle{
    font-size: 22px;
  }
  .stylequizPageStartPageTitle{
    font-size: 22px;
  }
}

.dropdown-menu{
  min-width: 100px !important;
}
.dropdown-item{
  text-align: center !important;
}
.dropdown-toggle::after{
  color: white;
}

@media only screen and (max-width: 992px) {
  .dropdown-menu{
width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .navbarContainerDiv  {
    font-size: 17px;
  }
}

@media only screen and (max-width: 992px) {
  .navbarContainerDiv  {
    font-size: 20px;
  }
}